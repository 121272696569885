<template>
  <Activities :data="activities" :loading="loading" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    Activities: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/activity/Overview.vue'),
  },

  computed: {
    ...mapGetters({
      loading: 'activity/loading',
      activities: 'activity/all',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('activity/loadAll');
    },
    destroyData() {
      this.$store.commit('activity/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
