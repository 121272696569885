<template>
  <v-row>
    <v-col cols="12" md="7">
      <SwitchActivity :data="activities" :loading="loadingActivities" />
    </v-col>
    <v-col cols="12" md="5">
      <div class="pl-md-5">
        <SwitchVenue :data="venues" :loading="loadingVenues" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    SwitchActivity: () => import(/* webpackChunkName: "activity" */ '@/components/scoped/activity/Switch.vue'),
    SwitchVenue: () => import(/* webpackChunkName: "venue" */ '@/components/scoped/venue/Switch.vue'),
  },

  computed: {
    ...mapGetters({
      organization: 'organization/get',
      activities: 'activity/all',
      loadingActivities: 'activity/loading',
      venues: 'venue/all',
      loadingVenues: 'venue/loading',
    }),
  },

  watch: {
    organization: {
      handler(value) {
        if (value) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    loadData() {
      if (this.organization) {
        this.$store.dispatch('activity/loadAllByOrganisation');
        this.$store.dispatch('venue/loadAllByOrganisation');
      }
    },
    destroyData() {
      this.$store.commit('activity/resetAll');
      this.$store.commit('venue/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
