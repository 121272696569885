<template>
  <Activities />
</template>

<script>
export default {
  components: {
    Activities: () => import(/* webpackChunkName: "organization-detail" */ '@/components/scoped/views/Activities.vue'),
  },
};
</script>
