<template>
  <div v-if="organization">
    <Organization :organization="organization" />
    <div class="headline mt-5">Benutzer</div>
    <Users :data="users" :loading="loadingUsers" />
    <template v-if="canHaveSubOrganizations">
      <div class="headline mt-5">Organisationen</div>
      <Organizations :data="organizations" :loading="loadingOrganizations" />
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  components: {
    Organizations: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/List.vue'),
    Organization: () => import(/* webpackChunkName: "organization" */ '@/components/scoped/organization/Detail.vue'),
    Users: () => import(/* webpackChunkName: "user" */ '@/components/scoped/user/List.vue'),
  },

  computed: {
    ...mapGetters({
      organization: 'organization/get',
      users: 'user/all',
      loadingUsers: 'user/loading',
      organizations: 'organization/all',
      loadingOrganizations: 'organization/loading',
    }),
    canHaveSubOrganizations() {
      return this.$can('create', 'Organization') && !this.$route.params.subOrganizationID;
    },
  },

  watch: {
    organization: {
      handler(value, oldValue) {
        if (value && !_.isEqual(value, oldValue)) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },

  methods: {
    loadData() {
      this.$store.dispatch('user/loadAllByOrganisation');
      this.$store.dispatch('organization/loadAllByOrganisation');
    },
    destroyData() {
      this.$store.commit('user/resetAll');
      this.$store.commit('organization/resetAll');
    },
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
