<template>
  <div v-if="organization">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      organization: 'organization/get',
      auth: 'auth/get',
    }),
  },

  methods: {
    loadData() {
      this.$store.dispatch('organization/load', this.$route.params.subOrganizationID || this.$route.params.organizationID || this.auth.organization);
      this.$store.dispatch('navigation/minimize');
    },
    destroyData() {
      this.$store.commit('organization/unset');
      this.$store.dispatch('navigation/maximize');
    },
  },

  created() {
    this.loadData();
  },

  destroyed() {
    if (this.auth && (this.$route.params.subOrganizationID || this.$route.params.organizationID || this.auth.organization)) {
      this.loadData();
    } else {
      this.destroyData();
    }
  },
};
</script>
