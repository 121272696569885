<template>
  <v-container fluid v-if="organization">
    <router-view />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      organization: 'organization/get',
    }),
  },
};
</script>
