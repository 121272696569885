<template>
  <div>
    <Form v-if="!loadingData" />
    <base-loading v-else />
  </div>
</template>

<script>
export default {
  components: {
    Form: () => import(/* webpackChunkName: "activity" */ '@/components/scoped/activity/form/Form.vue'),
  },

  data: () => ({
    loadingData: true,
  }),

  watch: {
    '$route.params.activityID': {
      handler(value) {
        if (!value) {
          this.$store.commit('activity/reset');
        }
      },
    },
  },

  methods: {
    loadData() {
      if (this.$route.params.activityID) {
        this.$store.dispatch('activity/load', this.$route.params.activityID).then((activity) => {
          if (activity) this.$store.commit('activity/sync');
          this.$data.loadingData = false;
        });
      } else {
        this.$data.loadingData = false;
      }
    },
    destroyData() {
      this.$store.commit('activity/reset');
      this.$data.loadingData = true;
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
